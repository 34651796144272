import { useEffect, useRef, useState } from "react";
import "./App.css";
import Button from "@mui/material/Button";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function App() {
  const [image, setImage] = useState("/images/banner.jpeg");
  const [uploadProgress, setUploadProgress] = useState(0);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch("https://llmguys.com/api/random-image");
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImage(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    const intervalId = setInterval(fetchImage, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleButtonClick = () => {
    (inputRef?.current as any).click();
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("images", file);
    setOpen(true);
    axios
      .post("https://llmguys.com/api/upload", formData, {
        onUploadProgress: (progressEvent: any) => {
          console.log(progressEvent);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      })
      .then((response) => {
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="img-container">
        <div className="img-slideshow">
          <img className="resize" src={image} alt="jaidka default"></img>
        </div>
      </div>

      <input
        ref={inputRef}
        accept="image/*,video/*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="raised-button-file" />
      <Button
        aria-label="delete"
        size="small"
        variant="outlined"
        style={{
          paddingLeft: "2em",
          paddingRight: "2em",
          padding: "1em",
          borderRadius: "12px",
          textTransform: "none",
        }}
        onClick={handleButtonClick}
      >
        Upload Photo
      </Button>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Uploading Image"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <LinearProgress variant="determinate" value={uploadProgress} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default App;
